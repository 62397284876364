function onDOMContentLoaded(_event: Event) {
  const links = document.querySelectorAll<HTMLAnchorElement>('.open-country-selector-modal');

  for (const link of links) {
    link.addEventListener('click', onShowSelectorClick);
  }
}

function onShowSelectorClick(event: MouseEvent) {
  event.preventDefault();
  showModal();
}

function onCloseButtonClick(_event: MouseEvent) {
  closeModal();
}

function onShroudClick(_event: WindowEventMap['shroud-clicked']) {
  closeModal();
}

function showModal() {
  const modal = document.querySelector<HTMLElement>('#country-selector-modal');
  modal.removeAttribute('inert');
  modal.classList.remove('hidden');

  const closeButton = modal.querySelector<HTMLButtonElement>('.close-button');
  closeButton.addEventListener('click', onCloseButtonClick);

  addEventListener('shroud-clicked', onShroudClick);

  type Detail = WindowEventMap['modal-show-requested']['detail'];
  const modalShowEvent = new CustomEvent<Detail>('modal-show-requested', {
    detail: {
      element: modal
    }
  });
  dispatchEvent(modalShowEvent);
}

function closeModal() {
  const modal = document.querySelector<HTMLElement>('#country-selector-modal');
  modal.setAttribute('inert', '');
  modal.classList.add('hidden');

  const closeButton = modal.querySelector<HTMLButtonElement>('.close-button');
  closeButton.removeEventListener('click', onCloseButtonClick);

  removeEventListener('shroud-clicked', onShroudClick);

  type Detail = WindowEventMap['modal-close-requested']['detail'];
  const modalCloseEvent = new CustomEvent<Detail>('modal-close-requested', {
    detail: {
      element: modal
    }
  });
  dispatchEvent(modalCloseEvent);
}

if (document.readyState === 'complete' || document.readyState === 'interactive') {
  setTimeout(onDOMContentLoaded);
} else {
  addEventListener('DOMContentLoaded', onDOMContentLoaded);
}
