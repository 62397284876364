import { assert } from '@integrabeauty/assert';
import type * as TrustPilotTypes from '@integrabeauty/trustpilot-types';

/**
 * Fetches public information about a business unit from TrustPilot's API.
 *
 * @param apiKey api key
 * @param businessUnitId business unit id
 * @param useCache whether to use Integra's own custom backend cache service in order to avoid the
 * unreliable TrustPilot API
 */
export async function fetchBusinessUnit(apiKey: string, businessUnitId: string, useCache = false) {
  assert(apiKey, 'Invalid api key');
  assert(businessUnitId, 'Invalid business unit id');

  const path = useCache ?
    '/apps/trustpilot-cache/lange-business-unit.json' :
    `/apps/trustpilot-api/v1/business-units/${businessUnitId}`;

  let headers: HeadersInit = {};
  if (!useCache) {
    headers = {
      'Content-Type': 'application/json',
      'apiKey': apiKey
    };
  }

  let response;
  try {
    response = await fetch(path, { headers });
  } catch (error) {
    error.url = path;
    throw error;
  }

  if (!response.ok) {
    const error: any = new Error('Trustpilot Site Rating Service Error ' +
      `${response.status} ${response.statusText} ${response.url}`);
    error.status = response.status;
    error.url = response.url;
    throw error;
  }

  // We are intentionally not using response.json() here because it obscures errors.
  let responseText;
  try {
    responseText = await response.text();
  } catch (error) {
    error.status = response.status;
    error.url = response.url;
    throw error;
  }

  let body;
  try {
    body = JSON.parse(responseText);
  } catch (error) {
    error.body = responseText;
    error.status = response.status;
    error.url = response.url;
    throw error;
  }

  if (!isResponseBody(body)) {
    const error: any = new Error('Unexpected response body');
    error.body = body;
    error.status = response.status;
    error.url = response.url;
    throw error;
  }

  return body;
}

function isResponseBody(value: any): value is TrustPilotTypes.FetchBusinessUnitResponseBody {
  return Number.isInteger(value?.numberOfReviews?.total);
}
