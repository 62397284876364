import type { CustomElement } from '@integrabeauty/custom-elements';
import styles from './index.scss';

/**
 * Renders a copyright message in the current year.
 */
class CopyrightElement extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return ['data-year'];
  }

  public shadowRoot!: ShadowRoot;

  readonly dataset!: {
    /**
     * The year to display in the copyright message.
     */
    year: string;
  };

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>`;
  }

  public connectedCallback() {
    this.render();
  }

  public attributeChangedCallback(_name: string, oldValue: string, newValue: string) {
    if (this.isConnected && oldValue !== newValue) {
      this.render();
    }
  }

  private render() {
    const year = this.dataset.year || '';
    this.shadowRoot.innerHTML =
      `<style>${styles}</style>Copyright &copy;${year} L'ange&trade;. All Rights Reserved.`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'copyright-element': CopyrightElement;
  }
}

if (!customElements.get('copyright-element')) {
  customElements.define('copyright-element', CopyrightElement);
}
