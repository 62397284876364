import { assert } from '@integrabeauty/assert';

/**
 * The Yotpo API returns a JSON array.
 *
 * @example [{"method":"bottomline","result":{"total":1234,"average_score":4.9}}]
 */
export type ResponseBody = {
  method: string;

  result: {
    average_score: number;
    total: number;
  };
}[];

/**
 * The pid parameter is mandatory and cannot contain the "/" symbol
 *
 * Include the empty params attribute - "params" : {} - for the badge method
 *
 * It is highly recommended to develop a cache mechanism to save the payload locally and to refresh
 * it periodically. The purpose of this caching is to avoid long loading times every time the widget
 * loads.
 *
 * @see https://apidocs.yotpo.com/reference/retrieve-the-reviews-payload-in-html#body
 */
interface RequestBody {
  /**
   * The store ID
   */
  app_key: string;

  /**
   * Defines if the response should be for a mobile device or a desktop
   */
  is_mobile?: 'false' | 'true';

  /**
   * An array of widget types (methods) and an hash of the parameters for each method
   */
  methods: {
    /**
     * The format to return the result
     *
     * * HTML (default)
     * * JSON
     */
    format?: string;

    method:
      'badge' |
      'bottomline' |
      'embedded' |
      'main_widget' |
      'questions_bottomline' |
      'testimonials';

    params?: {
      page?: string;
      pid?: string;

      /**
       * Boolean value to show or hide the average score in the bottom line.
       */
      skip_average_score?: boolean;
    };
  }[];
}

/**
 * Fetches site ratings for the given app key. This makes a call to Yotpo that is not a documented
 * part of its API. It was extracted from the internals of the Yotpo widget that came from the Yotpo
 * SDK.
 *
 * If caching is specified, then this fetches data from Integra's custom-made Yotpo cache service
 * instead of from Yotpo's API. Yotpo's API has proven extremely unreliable, resulting in tens of
 * thousands of errors per day, leading to a lot of bad renders. Integra's cache is substantially
 * more reliable, dropping the error rate down to a de minimis rate.
 *
 * @see https://apidocs.yotpo.com/reference/retrieve-the-reviews-payload-in-html
 */
export async function fetchSiteBottomline(appKey: string, useCache?: boolean) {
  assert(appKey, 'Invalid app key');

  const path = useCache ?
    '/apps/yotpo-reviews-cache/lange-bottomline.json' :
    '/apps/yotpo-static/batch';

  let requestInit: RequestInit;
  if (useCache) {
    requestInit = {};
  } else {
    // TODO: the Yotpo docs say the PID is mandatory, but we are not using it here. But this seems
    // to work. investigate what is happening here. Leave behind a comment if we are ok, or look
    // into the proper fix.

    const body: RequestBody = {
      app_key: appKey,
      methods: [
        {
          method: 'bottomline',
          params: {},
          format: 'json'
        }
      ],
      // @ts-expect-error the docs show that this is a string, this seems wrong, we need to
      // investigate and clarify the discrepancy and whether this is intentional
      is_mobile: false
    };

    requestInit = {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'post',
      body: JSON.stringify(body)
    };
  }

  let response;
  try {
    response = await fetch(path, requestInit);
  } catch (error) {
    error.url = path;
    throw error;
  }

  if (!response.ok) {
    const error: any = new Error(`Received status ${response.status} fetching bottomline stats`);
    error.code = response.status;
    error.url = response.url;
    throw error;
  }

  let responseText;
  try {
    responseText = await response.text();
  } catch (error) {
    error.code = response.status;
    error.url = response.url;
    throw error;
  }

  if (!responseText) {
    const error: any = new Error('Bottomline stats response body is undefined/null/empty');
    error.code = response.status;
    error.url = response.url;
    throw error;
  }

  let responseBody;
  try {
    responseBody = JSON.parse(responseText);
  } catch (error) {
    error.body = responseText;
    error.code = response.status;
    error.url = response.url;
    throw error;
  }

  if (!isResponseBody(responseBody)) {
    const error: any = new Error('Bottomline stats response body contains unexpected data');
    error.body = responseBody;
    error.code = response.status;
    error.url = response.url;
    throw error;
  }

  // Only return the first element of the array.
  return responseBody[0];
}

function isResponseBody(value: any): value is ResponseBody {
  return Array.isArray(value) && value.length > 0 && typeof value[0] === 'object' &&
    value[0] !== null && value[0].method === 'bottomline';
}
