export interface SubscribeInput {
  email?: string;
  first_name?: string;
  phone?: string;
  segment_anonymous_id?: string;
  shopify_client_id?: string;
  shopify_customer_id?: string;
}

export interface RequestBody {
  email?: string;
  first_name?: string;
  phone?: string;
  segment_anonymous_id?: string;
  shop_domain?: string;
  shopify_client_id?: string;
  shopify_customer_id?: string;
  sign_up_source_id?: string;
}

export type ResponseBody = Record<string, any>;

interface ErrorResponseBody {
  error: string;
}

/**
 * This uses the no-store cache property because we never want this request to use the cache.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Request/cache
 */
export async function subscribe(input: SubscribeInput) {
  const url = 'https://langehair.com/apps/attentive-relay/prod/v1/subscribe';

  const requestBody: RequestBody = {
    email: input.email,
    first_name: input.first_name,
    phone: input.phone,
    segment_anonymous_id: input.segment_anonymous_id,
    shop_domain: 'lange-beauty.myshopify.com',
    shopify_client_id: input.shopify_client_id,
    shopify_customer_id: input.shopify_customer_id,
    sign_up_source_id: '991550'
  };

  let response;
  try {
    response = await fetch(url, {
      body: JSON.stringify(requestBody),
      cache: 'no-store',
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      mode: 'cors'
    });
  } catch (error) {
    const ae = new AttentiveError((<Error>error).message);
    ae.request_body = requestBody;
    ae.url = url;
    throw ae;
  }

  // On success, we have a 204 No Content, so we do not attempt to fetch the response body. Calling
  // await response.json() can throw for 204s. So we either have a non-204 indicating an error or
  // we succeeded.

  if (response.status !== 204) {
    const error = new AttentiveError('There was a problem subscribing');
    error.request_body = requestBody;

    // If there is an issue in the backend subscribing, then we might have some information about
    // the error in the response body, so we have to try to extract the message.

    try {
      const responseBody = await response.json();
      error.response_body = responseBody;

      if (isErrorResponseBody(responseBody) && typeof responseBody.error === 'string' &&
        responseBody.error.length > 0) {
        error.message = responseBody.error;
      }
    } catch (error) {
      // ignore
    }

    error.status = response.status;
    error.url = response.url;
    throw error;
  }
}

function isErrorResponseBody(value: any): value is ErrorResponseBody {
  return typeof value?.error === 'string' && value.error.length > 0;
}

export class AttentiveError extends Error {
  request_body: any;
  response_body: any;
  status: number;
  url: string;

  constructor(message?: string) {
    super(message || 'Unknown');
  }
}
