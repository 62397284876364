import { fetchSiteBottomline } from '../../lib/yotpo-fetch-site-bottomline.js';

interface BottomlineCache {
  average_score: number;
  total: number;
}

/**
 * Fetch the site bottomline from Yotpo or from a cache from a previous fetch in the same session.
 * This is basically a decorator of the {@link fetchSiteBottomline} function that adds caching.
 *
 * Given the high failure rate of requests to Yotpo, this tries to cache and re-use any successful
 * previous request for the duration of the session. This is acceptable because the badge text is
 * never going to materially change during the lifetime of someone's browsing session.
 *
 * Failures to read from or write to the cache are suppressed. This falls back to fetching when the
 * cache is unavailable. There have been cases in the past where session storage is not available,
 * or routinely errors, such as on certain Apple iOS versions due to ITP.
 */
async function fetchSiteBottomlineCached(appKey: string) {
  const storageKey = 'yotpo_site_bottomline';

  // Check if we cached the value previously in this session. Accessing session storage is a best
  // effort. We want to fetch if session storage is unavailable or has errors, as it sometimes
  // does on Apple devices.

  let bottomline: BottomlineCache;

  try {
    const value = sessionStorage.getItem(storageKey);
    bottomline = JSON.parse(value);
  } catch (error) {
    console.log(error);
    // suppress and continue
  }

  // If we found cached results, return those instead of fetching.
  if (bottomline) {
    return bottomline;
  }

  // Fetch the rating. If we fail here, throw an exception. We do not couple with trace here and
  // instead leave that concern to the caller.

  // Use the custom Yotpo cache we created that is more reliable than Yotpo's CDN
  const useCache = true;

  const body = await fetchSiteBottomline(appKey, useCache);

  // That object should have a property named "result" that is a non-empty object and a badge as
  // a value of `method` property.

  // TODO: this check should not be necessary. The fetchSiteBottomline function should guarantee
  // the validity of the output.

  if (typeof body.result !== 'object' || body.method !== 'bottomline') {
    return;
  }

  // Best effort attempt to cache the result in session storage. We do a simple length check so that
  // we never cache the empty value, because that is a response we never expect to see. We are
  // using try/catch so that a failure to cache does not prevent us from yielding some result.

  if (body.result) {
    try {
      sessionStorage.setItem(storageKey, JSON.stringify(body.result));
    } catch (error) {
      console.log(error);
    }
  }

  return body.result;
}

function onDOMContentLoaded(_event: Event) {
  init().catch(console.warn);
}

/**
 * @todo instead of making a call to an API, implement a backend service that periodically saves the
 * data in Shopify, then switch to server side rendering with no http request involved
 */
async function init() {
  const badge = document.querySelector('yotpo-badge');
  if (!badge) {
    return;
  }

  const appKey = badge.dataset.appKey;
  if (!appKey) {
    console.warn('footer yotpo-badge is missing data-app-key');
    return;
  }

  try {
    const result = await fetchSiteBottomlineCached(appKey);
    if (result?.total) {
      badge.dataset.total = `${result.total}`;
    }

    if (result?.average_score) {
      badge.dataset.averageScore = `${result.average_score}`;
    }
  } catch (error) {
    // Even with a custom built caching system we are spammed by this error. There seems to be no
    // end to it short of switching to Shopify metafields and rendering via liquid. We know this
    // error about failing to fetch is very frequent. For now we are going to not bother with
    // tracing the error because it is just noise.

    console.warn(error);

    badge.classList.add('hidden');
  }
}

if (document.readyState === 'complete' || document.readyState === 'interactive') {
  setTimeout(onDOMContentLoaded);
} else {
  addEventListener('DOMContentLoaded', onDOMContentLoaded);
}
