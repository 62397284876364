import * as Cookie from '@integrabeauty/cookie';
import { isValidEmailAddress } from '@integrabeauty/person';
import * as uuid from 'uuid';
import * as AttentiveService from './attentive-service.js';

interface RequestData {
  email: string;
  first_name: string;
  phone: string;
}

function isValidPhone(phone: string) {
  return /^[+]?(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/.test(phone);
}

/**
 * Handles newsletter signup form in the footer submit event
 */
function onSubmit(event: SubmitEvent) {
  event.preventDefault();

  const submitButton = document.querySelector(
    'button[data-controller-id="subscribe-submit-button"]');
  submitButton.classList.add('submitting');
  submitButton.textContent = 'Submitting...';

  const subscribeNameError = document.querySelector<HTMLElement>(
    'div[data-controller-id="subscribe-name-error"]');
  const footerEmailInput = document.querySelector<HTMLInputElement>('#subscribe-email');
  const footerNameInput = document.querySelector<HTMLInputElement>('#subscribe-name');
  const phoneInput = document.querySelector<HTMLInputElement>('#subscribe-phone-number');

  const requestData = <RequestData>{
    email: footerEmailInput.value,
    first_name: footerNameInput.value,
    phone: phoneInput?.value
  };

  const nameError = !validateName(requestData.first_name);
  const emailError = !isValidEmailAddress(requestData.email);
  let phoneError = false;

  if (requestData.phone) {
    phoneError = !isValidPhone(requestData.phone);
  }

  const termsInputElement = document.querySelector<HTMLInputElement>('#subscribe-terms');
  let termsError = false;

  if (requestData.phone && termsInputElement) {
    termsError = !termsInputElement.checked;
  }

  const errors: Record<string, string | null> = {
    nameError: null,
    emailError: null,
    termsError: null,
    phoneError: null
  };

  const customerId = document.querySelector<HTMLInputElement>('#customer-id').value;
  const subscribeNameWrapper = document.querySelector<HTMLElement>(
    '[data-controller-id="subscribe-name-wrapper"]');
  if (nameError) {
    const errorText = 'Please enter a name';
    subscribeNameError.textContent = errorText;
    subscribeNameWrapper.classList.add('error');
    errors.nameError = errorText;
  } else {
    subscribeNameWrapper.classList.add('success');
  }

  const subscribeEmailWrapper = document.querySelector(
    '[data-controller-id="subscribe-email-wrapper"]');
  if (emailError) {
    const subscribeEmailError = document.querySelector<HTMLElement>(
      'div[data-controller-id="subscribe-email-error"]');
    const errorText = 'Please enter a Valid Email Address';
    subscribeEmailError.textContent = errorText;
    errors.emailError = errorText;

    const subscribeEmailWrapper = document.querySelector(
      '[data-controller-id="subscribe-email-wrapper"]');
    subscribeEmailWrapper.classList.add('error');
  } else {
    subscribeEmailWrapper.classList.add('success');
  }

  const subscribeTermsWrapper = document.querySelector<HTMLElement>(
    'div[data-controller-id="subscribe-terms-wrapper"]');
  if (termsError) {
    const subscribeTermsError = document.querySelector<HTMLElement>(
      'div[data-controller-id="subscribe-terms-error"]');
    const errorText = 'Please accept the Conditions and Terms above';
    subscribeTermsError.textContent = errorText;
    subscribeTermsWrapper.classList.add('error');
    errors.termsError = errorText;
  } else {
    subscribeTermsWrapper.classList.add('success');
  }

  const subscribePhoneWrapper = document.querySelector(
    '[data-controller-id="subscribe-phone-wrapper"]');
  if (phoneError) {
    const subscribePhoneError = document.querySelector<HTMLElement>(
      'div[data-controller-id="subscribe-phone-error"]');
    const errorText = 'Please enter a Phone Number';
    subscribePhoneError.textContent = errorText;
    errors.phoneError = errorText;
    subscribePhoneWrapper.classList.add('error');
  } else {
    subscribePhoneWrapper.classList.add('success');
  }

  if (nameError || emailError || phoneError || termsError) {
    const errorText = Object.values(errors).filter(Boolean);
    dispatchMarketingSubscribedEvent({
      customer_id: parseInt(customerId, 10),
      error_message: errorText.join('. '),
      event_id: uuid.v4(),
      subscribe_method: 'email/sms',
      subscribed_from: 'site_footer',
      success: false
    });

    submitButton.classList.remove('submitting');
    submitButton.textContent = 'Submit';
    return;
  }

  processForm(requestData, customerId).catch(console.warn);
}

function dispatchMarketingSubscribedEvent(detail: MarketingSubscribedEvent['detail']) {
  const event = new CustomEvent<typeof detail>('marketing-subscribed', { detail });
  dispatchEvent(event);
}

function validateName(name: string) {
  // Some browsers do not tolerate using an empty space in the character class so we use \s
  // explicitly, even though it matches multiple kinds of spaces.
  return /^[a-z\s]{2,30}$/i.test(name);
}

async function processForm(requestData: RequestData, customerId: string) {
  const resultElement = document.querySelector<HTMLElement>(
    'div[data-controller-id="subscribe-response"]');
  if (!resultElement) {
    return;
  }

  const subscribeInput: AttentiveService.SubscribeInput = {
    email: requestData.email,
    first_name: requestData.first_name,
    phone: requestData.phone,
    shopify_client_id: getShopifyClientId(),
    shopify_customer_id: customerId
  };

  const submitButton = document.querySelector(
    'button[data-controller-id="subscribe-submit-button"]');

  try {
    await AttentiveService.subscribe(subscribeInput);
    resultElement.classList.remove('error');
    resultElement.classList.add('success');
    resultElement.textContent = 'Thank you for registering!';

    const footerInputElement = document.querySelector<HTMLInputElement>(
      '#subscribe-phone-number');
    if (footerInputElement) {
      footerInputElement.value = '';
    }

    const footerTermsElement = document.querySelector<HTMLInputElement>('#subscribe-terms');
    if (footerTermsElement) {
      footerTermsElement.checked = false;
    }

    const formTerms = document.querySelector('div[data-controller-id="subscribe-terms-wrapper"]');
    if (formTerms) {
      formTerms.classList.add('hidden');
    }

    const inputs = document.querySelectorAll<HTMLInputElement>('form[name="subscribe"] input');
    for (const input of inputs) {
      input.value = '';
    }

    const subscribeFirstNameError = document.querySelector<HTMLElement>(
      'div[data-controller-id="subscribe-name-error"]');
    subscribeFirstNameError.innerHTML = '';

    const [firstName, lastName] = requestData.first_name.split(/\s/);

    const nameWrapper = document.querySelector<HTMLElement>(
      '[data-controller-id="subscribe-name-wrapper"]');
    nameWrapper.classList.remove('success');
    const emailWrapper = document.querySelector(
      '[data-controller-id="subscribe-email-wrapper"]');
    emailWrapper.classList.remove('success');
    const phoneWrapper = document.querySelector(
      '[data-controller-id="subscribe-phone-wrapper"]');
    phoneWrapper.classList.remove('success');
    const termsWrapper = document.querySelector(
      'div[data-controller-id="subscribe-terms-wrapper"]');
    termsWrapper.classList.remove('success');

    submitButton.classList.remove('submitting');
    submitButton.classList.add('success');
    submitButton.textContent = 'Success!';

    dispatchMarketingSubscribedEvent({
      customer_id: parseInt(customerId, 10),
      email: requestData.email,
      event_id: uuid.v4(),
      first_name: firstName,
      last_name: lastName,
      phone: requestData.phone,
      subscribe_method: 'email/sms',
      subscribed_from: 'site_footer',
      success: true
    });
  } catch (error) {
    // Careful here. We have to use the class produced from the dynamic import of the module, not
    // the type of the class from the imported type, the imported type is not a runtime class.

    resultElement.classList.remove('success');
    resultElement.classList.add('error');
    submitButton.classList.remove('submitting');
    submitButton.textContent = 'Submit';

    if (error instanceof AttentiveService.AttentiveError) {
      resultElement.innerHTML = error.message ||
        'There was a problem submitting your request, please try again later';
    } else {
      resultElement.innerHTML =
        'There was a problem submitting your request, please try again later';
      console.warn(error, 'Failed to subscribe in Attentive');
    }

    const formTerms = document.querySelector('div[data-controller-id="subscribe-terms-wrapper"]');
    formTerms.classList.add('hidden');

    dispatchMarketingSubscribedEvent({
      customer_id: parseInt(customerId, 10),
      error_message: error.message,
      event_id: uuid.v4(),
      subscribe_method: 'email/sms',
      subscribed_from: 'site_footer',
      success: false
    });
  }
}

function getShopifyClientId() {
  try {
    return Cookie.read('_shopify_y');
  } catch (error) {}
}

function onPhoneFieldFocus(_event: Event) {
  const subscribePhoneWrapper = document.querySelector(
    '[data-controller-id="subscribe-phone-wrapper"]');
  const formTerms = document.querySelector('div[data-controller-id="subscribe-terms-wrapper"]');
  const subscribePhoneError = document.querySelector(
    'div[data-controller-id="subscribe-phone-error"]');

  formTerms.classList.remove('hidden');
  subscribePhoneWrapper.classList.remove('error');
  subscribePhoneWrapper.classList.remove('success');
  subscribePhoneError.innerHTML = '';

  const subscribeTermsError = document.querySelector(
    'div[data-controller-id="subscribe-terms-error"]');
  subscribeTermsError.innerHTML = '';
}

function onPhoneFieldBlur(_event: Event) {
  const phoneField = document.querySelector<HTMLInputElement>('#subscribe-phone-number');
  const formTerms = document.querySelector('div[data-controller-id="subscribe-terms-wrapper"]');
  if (!phoneField.value) {
    formTerms.classList.add('hidden');
  } else {
    formTerms.classList.remove('hidden');
  }
}

function onTermsChange(event: Event) {
  const checkboxEl = <HTMLInputElement>event.target;
  if (checkboxEl.checked) {
    const terms = document.querySelector('div[data-controller-id="subscribe-terms-wrapper"]');
    terms.classList.remove('error');

    const subscribeTermsError = document.querySelector(
      'div[data-controller-id="subscribe-terms-error"]');
    subscribeTermsError.innerHTML = '';
  }
}

function onSubscribeNameFocus(_event: Event) {
  const errorElement = document.querySelector('div[data-controller-id="subscribe-name-error"]');
  errorElement.innerHTML = '';

  const wrapper = document.querySelector('[data-controller-id="subscribe-name-wrapper"]');
  wrapper.classList.remove('error');
  wrapper.classList.remove('success');
}

function onSubscribeEmailFocus(_event: Event) {
  const errorElement = document.querySelector('div[data-controller-id="subscribe-email-error"]');
  errorElement.innerHTML = '';

  const wrapper = document.querySelector('[data-controller-id="subscribe-email-wrapper"]');
  wrapper.classList.remove('error');
  wrapper.classList.remove('success');
}

function onDOMContentLoaded(_event: Event) {
  const terms = document.querySelector<HTMLInputElement>('#subscribe-terms');
  if (terms) {
    terms.addEventListener('change', onTermsChange);
  } else {
    console.log('subscribe terms element not found');
  }

  const phoneField = document.querySelector<HTMLInputElement>('#subscribe-phone-number');
  phoneField.addEventListener('focus', onPhoneFieldFocus);

  const subscribeButton = document.querySelector(
    'button[data-controller-id="subscribe-submit-button"]');
  subscribeButton.removeAttribute('disabled');

  const footerFormEmailElement = document.querySelector<HTMLFormElement>('form[name="subscribe"]');
  // TODO: we are seeing an error where the form is not found. This is a quick mitigation. look into
  // why the form is not always found. maybe we made some changes where the form is not appended and
  // forgot about this code that assumes it is always found. dig into this an either write a comment
  // or fix the issue.
  if (footerFormEmailElement) {
    footerFormEmailElement.addEventListener('submit', onSubmit);
  } else {
    console.log('footer subscription form not found on load');
  }

  phoneField.addEventListener('blur', onPhoneFieldBlur);

  const name = document.querySelector<HTMLInputElement>('#subscribe-name');
  name.addEventListener('focus', onSubscribeNameFocus);

  const email = document.querySelector<HTMLInputElement>('#subscribe-email');
  email.addEventListener('focus', onSubscribeEmailFocus);
}

/**
 * Fired when the user subscribes to marketing communications.
 *
 * This event is fired on both success and error. Do not assume the subscription was successful.
 *
 * Do not confuse a marketing subscription with a product subscription. Marketing is about receiving
 * emails and text messages. Products are about recurring purchases.
 */
type MarketingSubscribedEvent = CustomEvent<Partial<{
  /**
   * The id of the Shopify app that generated this event. This is used by certain event listeners
   * such as the Shopify analytics relay pixel.
   *
   * App developers should provide an appId to identify themselves when they call the visitor API on
   * the online store. Calling the visitor API without providing an appId can degrade data quality,
   * which makes it difficult for merchants to manage their data. To find your appId, you can use
   * the GraphQL Admin API. In checkout, you don't need to provide an appId because these are
   * managed in checkout UI extensions.
   *
   * @example "1234"
   * @see https://shopify.dev/docs/api/web-pixels-api/emitting-data#visitor-api
   */
  app_id?: string;

  /**
   * Shopify customer id
   */
  customer_id: number;

  /**
   * The email address that was subscribed
   */
  email: string;

  /**
   * A human readable description of an error that occurred while attempting to subscribe. This is
   * only set when success is false and is not guaranteed to be set even in that case.
   *
   * Do not use this to test whether the operation failed. Use the success property.
   */
  error_message: string;

  event_id: string;

  /**
   * Person first name
   */
  first_name: string;

  /**
   * Person last name
   */
  last_name: string;

  /**
   * Person full name
   *
   * @deprecated use first name and last name instead
   */
  name: string;

  /**
   * Person phone number (not formatted)
   */
  phone: string;

  /**
   * Describes how the person subscribed.
   *
   * @example "email/sms"
   * @example "email"
   */
  subscribe_method: string;

  /**
   * Describes from where the person subscribed
   */
  subscribed_from: string;

  /**
   * Whether the subscription operation succeeded
   */
  success: boolean;
}>>;

declare global {
  interface WindowEventMap {
    'marketing-subscribed': MarketingSubscribedEvent;
  }
}

if (document.readyState === 'complete' || document.readyState === 'interactive') {
  setTimeout(onDOMContentLoaded);
} else {
  addEventListener('DOMContentLoaded', onDOMContentLoaded);
}
