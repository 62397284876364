import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders a Trustpilot badge
 *
 * @todo this should use the default slot instead of an explicitly named single slot
 * @todo this element should not be in use on the product reviews page, this should only be used
 * within the footer
 */
class TrustpilotBadge extends HTMLElement implements CustomElement {
  static get observedAttributes() {
    return ['data-rating', 'data-total'];
  }

  readonly dataset!: {
    /**
     * Overall rating
     */
    rating: string;

    /**
     * Total number of votes
     */
    total: string;
  };

  public shadowRoot!: ShadowRoot;

  constructor() {
    super();

    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    this.render();
  }

  public attributeChangedCallback(_name: string, oldValue: string, newValue: string) {
    if (this.isConnected && oldValue !== newValue) {
      this.render();
    }
  }

  private render() {
    const rating = Math.min(parseFloat(this.dataset.rating), 5);
    if (!rating) {
      return;
    }

    const total = parseInt(this.dataset.total, 10);
    if (!total) {
      return;
    }

    this.shadowRoot.host.classList.add('visible');

    const scoreElement = this.querySelector<HTMLElement>('.business-info .trust-score');

    // We sometimes see an error where the score element is not found. Unclear why. It might be
    // related to using a slot in a custom element. So for now we are checking for the element and
    // doing nothing in that case. It might be because the start tag is parsed and the custom
    // element is deemed connected so the connectedCallback triggers a render prior to the child
    // nodes being parsed.

    if (scoreElement) {
      scoreElement.textContent = `${rating}`;
    }

    const totalElement = this.querySelector<HTMLElement>('.business-info .trust-total');
    if (totalElement) {
      const format = new Intl.NumberFormat('en-US');
      totalElement.textContent = format.format(total);
    }

    const starsElement = this.querySelector('trustpilot-rating-stars');
    if (starsElement) {
      starsElement.dataset.rating = `${rating}`;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'trustpilot-badge': TrustpilotBadge;
  }
}

if (!customElements.get('trustpilot-badge')) {
  customElements.define('trustpilot-badge', TrustpilotBadge);
}
