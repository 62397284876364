import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders Trustpilot SVG rating stars
 */
class TrustpilotRatingStars extends HTMLElement implements CustomElement {
  static get observedAttributes() {
    return ['data-rating'];
  }

  readonly dataset!: {
    /**
     * Rating, how many stars will be filled
     */
    rating: string;
  };

  public constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    this.render();
  }

  public attributeChangedCallback(_name: string, oldValue: string, newValue: string) {
    if (this.isConnected && oldValue !== newValue) {
      this.render();
    }
  }

  private render() {
    let rating = parseFloat(this.dataset.rating);
    rating = rating > 5 ? 5 : rating;

    if (!rating) {
      return;
    }

    if (rating === 5) {
      this.shadowRoot.host.classList.add('dark');
    }

    const starsElement = this.shadowRoot.querySelectorAll<SVGGElement>('svg g');
    for (let i = 0; i < starsElement.length; i++) {
      if (i + 1 <= rating) {
        starsElement[i].classList.add('filled');
      }

      if (i + 1.5 > rating) {
        starsElement[i].classList.add('filled-half');
        break;
      }
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'trustpilot-rating-stars': TrustpilotRatingStars;
  }
}

if (!customElements.get('trustpilot-rating-stars')) {
  customElements.define('trustpilot-rating-stars', TrustpilotRatingStars);
}
