import { fetchBusinessUnit } from './trustpilot-fetch-business-unit.js';

interface CachedRating {
  score: number;
  total: number;
}

async function fetchBusinessUnitCached(apiKey: string, businessUnitId: string) {
  const storageKey = 'trustpilot_site_rating';

  let rating: CachedRating;

  try {
    const value = sessionStorage.getItem(storageKey);
    if (value) {
      rating = JSON.parse(value);
      if (rating) {
        return rating;
      }
    }
  } catch (error) {
    console.log(error);
  }

  const body = await fetchBusinessUnit(apiKey, businessUnitId, true);

  rating = {
    score: body.score.trustScore,
    total: body.numberOfReviews.total
  };

  try {
    sessionStorage.setItem(storageKey, JSON.stringify(rating));
  } catch (error) {
    console.log(error);
  }

  return rating;
}

function onDOMContentLoaded(_event: Event) {
  // init calls out to fetch business unit and otherwise does nothing fallible. the only reason init
  // fails is because of a failure to fetch. the failure to fetch here is not really indicative of a
  // programming error. it is in fact routine to experience load failures because of networking
  // problems (e.g. unreliable cellular data). if something were truly systemic it would be visually
  // obvious. because the fetch errors are so routine, this does not capture the error at the error
  // or warning level.

  init().catch(console.log);
}

async function init() {
  const badges = document.querySelectorAll('trustpilot-badge');
  if (badges.length === 0) {
    return;
  }

  // cspell:disable
  const apiKey = 'zY69rTwFDPKLgqcVnM2Ai95NZ2V6NeAR';
  const businessUnitId = '5ccc9833442b4b00014a6eb9';
  // cspell:enable

  const businessUnit = await fetchBusinessUnitCached(apiKey, businessUnitId);
  for (const badge of badges) {
    badge.dataset.rating = `${businessUnit.score}`;
    badge.dataset.total = `${businessUnit.total}`;
  }
}

if (document.readyState === 'complete' || document.readyState === 'interactive') {
  setTimeout(onDOMContentLoaded);
} else {
  addEventListener('DOMContentLoaded', onDOMContentLoaded);
}
